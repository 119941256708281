

























import { Component, Vue } from "vue-property-decorator";
import NotificationsManager from "@/components/NotificationsManager.vue";
import { readIsLoggedIn } from "@/store/main/getters";
import { dispatchCheckLoggedIn } from "@/store/main/actions";
import posthog from "posthog-js";

if (
  !window.location.href.includes("127.0.0.1") &&
  !window.location.href.includes("localhost")
) {
  posthog.init("phc_sIBMMHiIjqo3UYROGlNLb7Uo51FYpAfkTDmOMZmiKpD", {
    api_host: "https://app.posthog.com",
  });
}

@Component({
  components: {
    NotificationsManager,
  },
})
export default class App extends Vue {
  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }

  public async created() {
    await dispatchCheckLoggedIn(this.$store);
  }
}
